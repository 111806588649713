import {Link, useLocation} from '@remix-run/react'
import {ArrowNarrowRightIcon, KubernetesIcon} from './icons'

const showBanner = true
const kubecon = false
const hideOnPages: string[] = [] // ['/drop-week']

export function BannerAnnouncement() {
  const {pathname} = useLocation()
  if (!showBanner) return null
  if (hideOnPages.includes(pathname)) return null

  if (kubecon) {
    return (
      <div className="z-10 flex items-center justify-center gap-1 border-b border-radix-purple6 bg-gradient-to-b from-radix-purple1 to-radix-purple2 py-4 font-medium leading-none text-radix-purple11">
        <KubernetesIcon className="text-radix-purple12" />
        <div>Visit us at KubeCon booth P33!</div>
      </div>
    )
  }

  return (
    <Link
      to="/blog/introducing-github-actions-ultra-runners"
      className="z-10 flex items-center justify-center gap-1 border-b border-radix-violet6 bg-gradient-to-b from-radix-violet1 to-radix-violet2 py-4 font-medium leading-none text-radix-violet12"
    >
      <div>🚀 Introducing Ultra Runners — Up to 3x faster GitHub Actions jobs</div>
      <ArrowNarrowRightIcon className="h-4 w-4 text-radix-violet11" />
    </Link>
  )
}
